<template>
    <div class="all">
        <div class="title">
            <div>CL商学院 </div>
            <div>
                CL商学院以企业战略和人才开发为核心，为培养美育人才而专门设置，给全国千家门店提供平台，培养能适用的人才为主教育机构。CL商学院运作核心是知识和人才，通过科学的教学机制的设计打造企业知识资源体系，建设知识管理体系；打造出合格的美育人才队伍。
            </div>
        </div>

        <div class="d-flex flex-row flex-wrap center">
            <div class="kecheng-p col-12 " v-for="(item,index) in overviews" v-if="index<=5" :key="index">
                <div class=" p-0 rounded-ssm  d-flex flex-row">
                    <div class="p-0 banner" v-if="index%2!=0">
                        <el-carousel :loop="true" arrow="nerver" indicator-position="none" class="banner">
                            <el-carousel-item v-for="item1 in item" :key="item1">
                                <div class="d-flex justify-content-center h-100">
                                    <img :src="$imgURL+item1" class="w-100 h-100 obj-cover" alt="">
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class=" bg-kecheng d-flex  px-2 flex-column justify-content-center "
                        :class="[index==1||index==2||index==5||(index==4 && isSmall)?'text-white':'',index==1||(index==5 && !isSmall)||(index==4 && isSmall)?'bg-yellow1':'',index==2||(index==5&&isSmall)?'bg-blue':'',index%2==0?'b':'y']">
                        <div class="font-smd  font-weight-bold">CL商学院</div>
                        <div class="font mt-0-5">
                            {{index%2==0?`具有完整的学院结构，即同样具有学习内容、学员、师资、教学方式、教学机制、管理部门等主要模块，同时根据合作门店的实际状况具备特有的个性化组织形式。

                            `:`面授培训为主，线上学习为辅的混合式培训模式。专业化，体系化，效果最大化。
                            `}}</div>
                    </div>



                    <div class="p-0 banner" v-if="index%2==0">
                        <el-carousel arrow="nerver" indicator-position="none" class="banner">
                            <el-carousel-item v-for="item1 in item" :key="item1">
                                <div class="d-flex justify-content-center h-100">
                                    <img :src="$imgURL+item1" class="w-100 h-100 obj-cover" alt="">
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>

            </div>
        </div>
    </div>


</template>

<script>


export default {
    data() {
        return {
            overviews: [],
            windowWidth: '',
            isSmall: '',
            isMiddle: '',
        }

    },
    mounted() {
        let that = this
        that.isSmall = document.body.clientWidth < 567 ? true : false
        that.isMiddle = document.body.clientWidth <= 1024 && document.body.clientWidth > 567 ? true : false
        let type = this.$route.query.type
        // this.scrollView(type)
        this.windowWidth = window.innerWidth
        window.onresize = () => {
            if (!this.timer) { // 使用节流机制，降低函数被触发的频率
                this.timer = true;
                let that = this; // 匿名函数的执行环境具有全局性，为防止this丢失这里用that变量保存一下
                setTimeout(function () {
                    that.searchFormWidth();
                    that.timer = false;
                    that.isSmall = document.body.clientWidth < 567 ? true : false
                    that.isMiddle = document.body.clientWidth <= 1024 && document.body.clientWidth > 567 ? true : false
                }, 400)
            }
        }
    },
    created() {

        this.curriculums();
        // this.getPageBanner();
        this.getoverviews()
    },
    methods: {
        curriculums() {
            this.$axios({
                method: "post",
                url: this.$apiURL + "api/Curriculums/index",
            }).then((res) => {
                if (res.code < 1) {
                    console.log("网络错误");
                    return false;
                }
                this.configs = res.data.data.configs;
                this.classs = res.data.data.classs;
                // this.overviews = res.data.data.overviews;
                this.systems = res.data.data.systems;
            });
        }, // 获取运营驻店指导轮播图
        getoverviews() {
            this.$axios({
                method: "post",
                url: this.$apiURL + "/api/index/clList",
            }).then((res) => {

                this.overviews = Object.values(res.data.data)
                console.log("轮播图列表", this.overviews);
            });
        }

    }
}
</script>

<style lang="scss" scoped>
.title {
    width: 100%;
    height: 5.72rem;
    opacity: .6;
    background-image: url('../assets/img/clback.jpg');
    background-size: 100%;
    background-position: 50%;
    opacity: .6;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;

    div {
        text-align: center;
        color: #FFFFFF;

        &:nth-child(1) {
            font-size: 0.66rem;
            font-family: Galvji;
            font-weight: bold;

        }

        &:nth-child(2) {
            width: 60%;
            margin: 0 auto;
            font-size: .24rem;
            font-family: Heiti SC;
            font-weight: 300;
            text-indent: .5rem;
        }
    }
}

.rounded-ssm {
    border-radius: 10px;
    overflow: hidden;
}

.center {
    width: 80%;
    margin: 0 auto;
    margin-top: .75rem;

    .banner {
        width: 9.91rem;
        height: 7.66rem;

        img {
            object-fit: cover;
        }

        ::v-deep .el-carousel__container {
            height: 100% !important;
        }
    }

    .b {
        background-color: #9FDAF6;
    }

    .y {
        background-color: #FDD23E;
    }

    .el-carousel__container {
        height: 100% !important;
    }

    .kecheng-p {
        margin-bottom: .88rem;
    }

    .flex-row {
        flex-direction: row-reverse !important;
    }

    .font-smd {
        font-size: .36rem;
        font-family: Heiti SC;
        font-weight: 500;
        color: #000000;
        line-height: .6rem;
    }

    .font {
        font-size: .24rem;
        color: #6A6A6A;
        line-height: .6rem;
    }
}
</style>
